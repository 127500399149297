import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import HeadTags from "shared/HeadTags";
import Registration from './Registration';
import Login from './Login';
import ym from 'react-yandex-metrika';
import { User } from "services/api/types/User";

const AuthorizationPage = ({setPageClass, setToken, setUserData}: {setPageClass?: (pageClass: string) => void, setToken: (token: string) => void, setUserData: (userData: User) => void}) => {
	const [isUser, setIsUser] = useState(true);

	useEffect(() => {
		if (isUser) {
			setPageClass('login-page');
		} else {
			setPageClass('authorization-page');
		}
    }, [isUser]);
	
	useEffect(() => {
        ym('hit', `/login`);
		const _tmr = window['_tmr'] || (window['_tmr'] = []);
    	_tmr.push({ id: "3474920", type: "pageView", url: window.location.href });
    }, []);

	return (
		<div className="container authorization">
			<HeadTags
				title='Авторизация'
				metaDescription='Авторизация'
			/>
			<h1 className="h2">Авторизация</h1>
			<div className="authorization__tabs">
				<button type="button" className={`authorization__tab-item ${isUser? 'active': ''}`} onClick={() => setIsUser(true)}>Вход</button>
				<button type="button" className={`authorization__tab-item ${isUser? '': 'active'}`} onClick={() => setIsUser(false)}>Регистрация</button>
			</div>
			{isUser 
				? <Login setToken={setToken} setUserData={setUserData}/>
				: <Registration/>
			}
		</div>
	)
}
export default AuthorizationPage;