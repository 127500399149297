import { Link, NavLink, useLocation } from 'react-router-dom';
import { useEffect, useState, useRef, useContext } from 'react';
import Menu from 'features/Menu/Menu';
import { ViewportContext } from 'app/viewportContext';
import { User } from 'services/api/types/User';
import {
	CSSTransition ,
	TransitionGroup
  } from 'react-transition-group';

import ym from 'react-yandex-metrika';
import { Token } from 'services/api/types/User';

const logoImg = require("assets/images/logo.svg").default;

const Header = ({addedClass, token, userData}: {addedClass?: string, token: string, userData: User|undefined} ) => {
	const breakpoint = 768;
	const width = useContext(ViewportContext);
	const location = useLocation();
	const [isMobile, setIsMobile] = useState(false);
	const [showBurgerMenu, setShowBurgerMenu] = useState(false);
	const headerRef = useRef(null);

	useEffect(() => {
		setIsMobile(width <= breakpoint);
	}, [width]);

	useEffect(() => {
		setShowBurgerMenu(false);
	}, [location]);

	return (
		<header ref={headerRef}>
			<div className={`header ${addedClass} container`}>
                <Link to="/" className="header__logo">					
                    <img src={logoImg} width="375" alt="Логотип компании"/>
                </Link>
                <div 
					className="toggle-wrapper"
					onClick={() => setShowBurgerMenu(state => !state)}>
                    <div className={`menu-icon menu-icon-3 ${showBurgerMenu ? 'opened' : ''}`}>
                        <div className="bar bar-1"></div>
                        <div className="bar bar-2"></div>
                        <div className="bar bar-3"></div>
                    </div>
                </div>
                {/* <a className="header__phone tel" href="tel:+79063077170">+7 (906) 307-71-70</a> */}
				{
					token !== undefined ? 
					<Link className="header__account header__user btn" to={'/lk/my-orders'}>
						<span>{userData?.first_name}</span>
						<span>{userData?.last_name}</span>
					</Link>:
					<Link className="header__account gradient btn" to={'/auth'}>личный кабинет</Link>
				}
                <nav className="anim">
					<TransitionGroup component={null}>
						{(!isMobile || (isMobile && showBurgerMenu)) && 
							<CSSTransition timeout={{
								enter: 150,
								exit: 150,
							}}>
								<div className="menu_wrap">
									<Menu/>
									{
										token !== undefined ? 
										<Link className="header__account header__user btn" to={'/lk/my-orders'}>
											<span>{userData?.first_name}</span>
											<span>{userData?.last_name}</span>
										</Link>:
										<Link className="header__account gradient btn" to={'/auth'}>личный кабинет</Link>
									}
								</div>
							</CSSTransition>
						}
					</TransitionGroup>
                </nav>
            </div>
		</header>
	)
}
export default Header;