import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCreateLoginFormRequestMutation, useUpdateUserDataMutation } from "services/api/api";
import { LoginFormRequest } from "services/api/types/FormRequest";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { useState } from 'react';
import { User } from 'services/api/types/User';

const Login = ({setToken, setUserData}: {setToken: (token: string) => void, setUserData: (userData:User) => void}) => {
    const navigate = useNavigate();
	const [createFormRequest] = useCreateLoginFormRequestMutation();
    const [updateUserData] = useUpdateUserDataMutation();
    const validationsSchema = yup.object().shape({
        email: yup.string()
            .email('Введите верный email')
            .required('Обязательно к заполнению'),
        password: yup.string()
            .min(8, 'Слишком короткий пароль')
            .required('Обязательно к заполнению'),
        check: yup.bool(),
    });
    const [resultShow, setResultShow] = useState(false);
    const [resultText, setResultText] = useState({
        status: '',
        text: ''
    });
    return (
        <>
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                    remember: true
                }}
                validateOnBlur
                onSubmit={async ({ email, password, remember }, {resetForm}) => {
                    const body: LoginFormRequest = {
                        email,
                        password
                    };
                    let res = await createFormRequest({ body });
                    if (res['error']) {
                        setResultShow(true);
                        setResultText({
                            status: '',
                            text: res['error'].data?.non_field_errors ?  res['error'].data?.non_field_errors.join('; ') : 'Неверные данные'
                        });
                    } else {
                        resetForm();
                        setToken(res['data'].access);                        
                        localStorage.setItem('token', res['data'].access);
                        if (remember) localStorage.setItem('refresh', res['data'].refresh);
                        navigate('/lk/my-orders');  
                        let response = await updateUserData();
                        if(response['data']) {
                            setUserData(response['data']);
                        } else {
                            setUserData(undefined);
                        }          
                    }
                    
                }}
                validationSchema={validationsSchema}
            >
                {({
                    handleSubmit,
                    isSubmitting
                }) => (
                    <Form className="form profile-form profile-form--login" onSubmit={handleSubmit}>
                        <div className="form__field">
                            <Field
                                type="email"
                                name="email"
                                placeholder="E-MAIL" /> 
                            <ErrorMessage name="email" component="span" className="form__error" />
                        </div>
                        <div className="form__field">
                            <Field
                                type="password"
                                name="password"
                                placeholder="ПАРОЛЬ" /> 
                            <ErrorMessage name="password" component="span" className="form__error" />
                            <Link to="/lk/vosstanovlenie-parolya" className="profile-form__link">Я не помню пароль</Link>
                        </div>
                        <div className="form__check">
                            <Field
                                className="visually-hidden"
                                name="remember"
                                type="checkbox"
                                checked
                                id="remember" />
                            <label htmlFor="remember"><span>Запомнить меня</span></label>
                        </div>
                        <button type="submit" disabled={isSubmitting} className="form__btn btn gradient">ВОЙТИ</button>
                    </Form>
                )}
            </Formik>
            {resultShow && <div className="profile-form__result">{resultText.text}</div>}
        </>
    );
}
export default Login;