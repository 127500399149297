import useParamInt from "app/hooks";
import { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGetRetreatsListQuery } from 'services/api/api';
import LoadingBlock from 'shared/LoadingBlock';
import Pagination from "shared/Pagination";
import HeadTags from "shared/HeadTags";
import ConsultForm from "shared/ConsultForm";
import { RetreatItem } from "services/api/types/News";
import FormatDate from "lib/FormatDate";
import ym from 'react-yandex-metrika';

import retrit_1 from "assets/images/retrit_1.jpg";
import retrit_2 from "assets/images/retrit_2.jpg";

const RetreatListItem = ({item}: {item: RetreatItem}) => {
    const {dateDM} = FormatDate(item.date);
    return (
        <li>
            <Link to={`/uslugi/retriti/${item.slug}`} className="retreat-list-item">
                <img
                    src={item.thumbnail}
                    alt={item.title}
                    className="retreat-list-item__photo" />
                <span className="retreat-list-item__name">{item.title}</span>
                <span className="retreat-list-item__date">
                    <span>Дата: <strong>{dateDM}</strong></span>
                    {item.duration && <span>Продолжительность - {item.duration}</span>}
                </span>
                <span className="retreat-list-item__lead">{item.lead}</span>
                <span className="retreat-list-item__link btn gradient">подробнее</span>
            </Link>
        </li>
    );
};

const RetreatsPage = ({ setPageClass }) => {
    const [limit, setLimit] = useState<number>(24);
    const [offset, setOffset] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const location = useNavigate();
    const ref = useRef(null);
    const { data, isLoading } = useGetRetreatsListQuery({limit, offset});

    useEffect(() => {
        setOffset(0);
        setPage(1);
        setLimit(24);
    }, [location]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [page]);

    useEffect(() => {
        setPageClass('retreat-page');
        ym('hit', `/uslugi/retriti`);
        const _tmr = window['_tmr'] || (window['_tmr'] = []);
         _tmr.push({ id: "3474920", type: "pageView", url: window.location.href });
    }, []);

    const list = data
        ? data.results.length
            ? data.results.map(item => {
                return (
                    <RetreatListItem key={item.id} item={item} />
                )
            })
            : <p className="center">Раздел находится в разработке</p>
        : 'Загрузка...';

    return (
        <>
            <HeadTags
                title='Ретриты с психологом - Елена Полянская'
                metaDescription='Ретрит - это путешествие за собой с психологом Еленой Полянской. Расписание. Цены. Контакты.'
            />
            <section className="retreat container">
                <ul className="breadcrumbs" itemScope itemType="http://schema.org/BreadcrumbList">
                    <li className="breadcrumb-item" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                        <Link to="/" itemProp="item">Главная</Link>
                    </li>
                    <li className="breadcrumb-item" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                        <Link to="/uslugi" itemProp="item">УСЛУГИ</Link>
                    </li>
                    <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem"><span itemProp="item">Ретриты</span></li>
                </ul>
                <h1 className="h2">Ретрит с психологом</h1>
                <div className="retreat-block">
                    <div className="retreat-block__text">
                        <p className="retreat-block__big">Ретрит — это путешествие за собой. Это раздетое от привычных форм Я.</p>
                        <p>Это телепортация туда, где нет привычных запахов, вкусов, привычек и моделей.</p>
                        <p>Ты входишь в аэропорт, садишься в самолет, и через несколько часов ты в новом: рядом нет мужа/жены, коллег, мамы, друзей, детей, проблем, привычных ритуалов. Ты вдруг обнаруживаешь себя, которому не надо делать, решать привычные задачи-схемы. А вокруг дымка в горах, море и незнакомые слова, и на тебя по-новому смотрят другие глаза.</p>
                    </div>
                    <img src={retrit_1} className="retreat-block__photo" alt="Ретрит"/>
                </div>
                <div className="retreat-text">
                    <div className="retreat-text__orange">
                        <p>Это шанс. Получить себя для себя по-новому. Начать жить другие чувства и отношения.</p>
                        <p>Я ищу место с такими людьми, которые, не участвуя напрямую в процессе ретрита, создают атмосферу.</p>
                    </div>
                    <div className="retreat-text__nobg">
                        <p className="retreat-text__big">Группа.</p>
                        <p>Группа – это феномен попутчиков в поезде. Ты являешь себя и уходишь, что-то отдав и что-то взяв от другого. Вы ничем не связаны, можете расстаться навсегда.</p>
                        <p>Технически — мы приезжаем, вечером собираемся в круг и показываем себя друг другу.</p>
                        <p>И достаем и отряхиваем свои «хочухи» — хочу замуж, детей, денег, работы…</p>
                        <p>Дальше моя работа — «хочухи» собрать и помочь договориться с ними. Работает внутри меня психолог, расстановщик и старуха, которой 300 лет. Старуха понятней всего рассказывает, что и как она делает. Если будут вопросы — задайте, я расскажу в образах — как ищу травмированные и потерянные части души, показываю, как договариваться и возвращать их. Возвращать целостность себе и лечить раны — раненое женское и мужское, человеческое. Всё это завариваем с кайфом от места, событий и моря, которое всегда рядом в наших ретритах. Но работа отдельно — 2-3-4 часа в день, а кайф повсеместно.</p>
                        <p>Параллельно с этим Кот Учёный внутри меня иногда мурчит-бурчит о том, как устроена психика, семейные системы, почему люди сходят с ума и как создают пары. Но кот одомашнен и слушается, когда я его затыкаю.</p>
                        <p>В конце шабаш – собираемся увидеть-показать себя новых. Я работаю на облегчение. Иногда оторвать с горба приросшее несчастье больно и непривычно, тогда разгрузка по силам, желанию-запросу, сколько душа сможет-попросит.</p>
                    </div>
                </div>
            </section>
            <div className="retreat-gray">
                <div className="retreat-block retreat-block--right container">
                    <div className="retreat-block__text">
                        <p className="retreat-block__big">Где проходят ретриты?</p>
                        <p>За этот год самый первый был в Сиде про любовь. Любовь Рода и к Роду. Следующий в Дагомысе — про любовь и деньги. Потом в Дагестане про… как трудно жить и жить легче. Потом был Стамбул и путешествие за собой отдельным от… Психологи назовут это сепарацией. Мы поедем снова в начале сентября за своим женско-мужским, полеченным от ран. За способностью любить и сострадать себя. За крыльями за спиной, за способностью жить так, как этого от нас требует душа и новые обстоятельства.</p>
                        <p>И в ноябре поедем за теплом души в теплые места.</p>
                    </div>
                    <img src={retrit_2} className="retreat-block__photo" alt="Ретрит"/>
                </div>
            </div>
            <section className="retreat-bottom container">
                <h2>Расписание ретритов</h2>
                <LoadingBlock isLoading={isLoading}>
                    <>
                        <ul className="retreat-list" itemScope itemType="http://schema.org/ItemList" ref={ref}>
                            {list}
                        </ul>
                        {data &&
                            <Pagination
                                pageSize={limit}
                                currentPage={page}
                                totalCount={data.count}
                                onPageChange={page => {
                                    if (page === Infinity) {
                                        setLimit(data.count);
                                        setOffset(0);
                                        setPage(1);
                                    } else {
                                        setOffset((page - 1) * limit);
                                        setPage(page);
                                    }
                                }}
                            />
                        }
                    </>
                </LoadingBlock>
                <ConsultForm/>
            </section>
        </>
    )
}
export default RetreatsPage;