import React, { Suspense, useEffect, useState, useMemo } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { YMInitializer } from 'react-yandex-metrika';
import Header from 'features/Header/Header';
import Footer from 'features/Footer/Footer';
import HomePage from 'features/HomePage/HomePage';
import ContactsPage from 'features/ContactsPage/ContactsPage';
import VideoPage from 'features/VideoPage/VideoPage';
import ServicesPage from 'features/Services/ServicesPage';
import FaqPage from 'features/FaqPage/FaqPage';
import TeamListPage from 'features/Team/TeamListPage/TeamListPage';
import TeamItemPage from 'features/Team/TeamItem/TeamItemPage';
import PoluanskayaPage from 'features/Team/PoluanskayaPage';
import ConsultationPage from 'features/Services/ConsultationPage';
import GroupPage from 'features/Services/GroupPage';
import NewsPage from 'features/News/NewsPage';
import NewsItemPage from 'features/News/NewsItemPage';
import BlogPage from 'features/Blog/BlogPage';
import BlogItemPage from 'features/Blog/BlogItemPage';
import WebinarsPage from 'features/Webinars/WebinarsPage';
import WebinarPage from 'features/Webinars/WebinarPage';
import CoursesPage from 'features/Cources/CoursesPage';
import CoursePage from 'features/Cources/CoursePage';
import RetreatsPage from 'features/Retreats/RetreatsPage';
import RetreatPage from 'features/Retreats/RetreatPage';
import TestsPage from 'features/Tests/TestsPage';
import TestPage from 'features/Tests/TestPage';
import EducationGroupPage from 'features/Services/EducationGroupPage';
import NotFound from 'features/Errors/NotFound';
import AuthorizationPage from 'features/User/AuthorizationPage';
import RecoveryPage from 'features/User/RecoveryPage';
import WelcomePage from 'features/User/WelcomePage';
import ConfirmRegisterPage from 'features/User/ConfirmRegisterPage';
import ConfirmPasswordPage from 'features/User/ConfirmPasswordPage';
import AccountPage from 'features/User/Account/AccountPage';
import ProfilePage from 'features/User/Account/ProfilePage';
import OrdersPage from 'features/User/Account/OrdersPage';
import OrderPage from 'features/User/Account/OrderPage';
import SupportPage from 'features/User/Account/SupportPage';
import { ViewportProvider } from 'app/viewportContext';
import Spinner from 'shared/Spinner';
import { useCreateCheckTokenValidRequestMutation, useCreateRefreshTokenRequestMutation, useGetUserDataQuery } from 'services/api/api';
// import VkPixel from 'react-vk-pixel';
import BookingPage from 'features/User/Account/BookingPage';
import GdprModal from 'features/Modals/GdprModal';

function App() {
  const [buttonUp, setButtonUp] = useState(false);
  const [pageClass, setPageClass] = useState('');
  const [token, setToken] = useState<string|undefined>(localStorage.getItem('token') || undefined);
  const [createCheckTokenFormRequest] = useCreateCheckTokenValidRequestMutation();
  const [createRefreshTokenFormRequest] = useCreateRefreshTokenRequestMutation();
  const [userData, setUserData] = useState(undefined);
  const { data: user } = useGetUserDataQuery();
  
  useEffect(() => {
    if (user) setUserData(user);
  }, [token, user]);

  const checkToken = async () => {
		let res = await createCheckTokenFormRequest({token});
    const refresh = localStorage.getItem('refresh'); 
		if (res['error']) {
      if (refresh) {
        let refreshResult = await createRefreshTokenFormRequest({refresh});
        if (refreshResult['error']) {
          console.log(refreshResult['error']);
          setToken(undefined);
          localStorage.removeItem('token');
          localStorage.removeItem('refresh');
        }
      } else {
        console.log(res['error']); 
        setToken(undefined);
        localStorage.removeItem('token');
      }      
		}    
	};
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    } );
  };
  const toggleScrollBtnVisible = () => { 
    const scrolled = document.documentElement.scrollTop; 
    if (scrolled > 600) { 
      setButtonUp(true);
    }  
    else if (scrolled <= 600) { 
      setButtonUp(false);
    } 
  }; 
  window.addEventListener('scroll', toggleScrollBtnVisible); 
  useEffect(() => {
    if (token !== undefined) {
      checkToken();      
    }
  }, []);
  return (
    <BrowserRouter>
      <ViewportProvider>
        <div className={`App ${pageClass}`}>
          <YMInitializer accounts={[95387652]} options={{
            defer: true,
            clickmap:true,
            trackLinks:true,
            accurateTrackBounce:true,
            webvisor:true
          }} />
          <YMInitializer accounts={[95524638]} options={{
            defer: true,
            clickmap:true,
            trackLinks:true,
            accurateTrackBounce:true,
            webvisor:true
          }} />
          {pageClass === 'main-page'
            ? <div className="main-top">
                <Header addedClass='header--main' token={token} userData={userData}/>
                <div className="top container">
                  <h1 className="h2">
                    <span className="top__title">свобода</span>
                    <span className="top__title top__title--right">ответственность</span>
                    <span className="top__title">ОСОЗНАННОСТЬ</span>
                    <span className="top__title top__title--right">удовольствие</span>
                  </h1>
                </div>
              </div>
            : <Header token={token} userData={userData}/>
          }
          <Suspense fallback={<Spinner/>}>
            <Routes>
              <Route path="/" element={<HomePage setPageClass={setPageClass}/>} />
              <Route path="/komanda" element={<TeamListPage setPageClass={setPageClass}/>} />
              <Route path="/komanda/:slug" element={<TeamItemPage setPageClass={setPageClass} />} />
              <Route path="/elena-polyanskaya" element={<PoluanskayaPage setPageClass={setPageClass} />} />
              <Route path="/uslugi" element={<ServicesPage setPageClass={setPageClass}/>} />
              <Route path="/video" element={<VideoPage setPageClass={setPageClass}/>} />
              <Route path="/novosti" element={<NewsPage setPageClass={setPageClass}/>} />
              <Route path="/novosti/:slug" element={<NewsItemPage setPageClass={setPageClass}/>} />
              <Route path="/blog" element={<BlogPage setPageClass={setPageClass}/>} />
              <Route path="/blog/:slug" element={<BlogItemPage setPageClass={setPageClass}/>} />
              <Route path="/vebinari" element={<WebinarsPage setPageClass={setPageClass}/>} />
              <Route path="/vebinari/:slug" element={<WebinarPage setPageClass={setPageClass}/>} />
              <Route path="/kursi" element={<CoursesPage setPageClass={setPageClass}/>} />
              <Route path="/kursi/:slug" element={<CoursePage setPageClass={setPageClass}/>} />
              <Route path="/uslugi/retriti" element={<RetreatsPage setPageClass={setPageClass}/>} />
              <Route path="/uslugi/retriti/:slug" element={<RetreatPage setPageClass={setPageClass}/>} />
              <Route path="/testi" element={<TestsPage setPageClass={setPageClass}/>} />
              <Route path="/testi/:slug" element={<TestPage setPageClass={setPageClass}/>} />
              <Route path="/poddergka" element={<FaqPage setPageClass={setPageClass}/>} />
              <Route path="/kontakty" element={<ContactsPage setPageClass={setPageClass}/>} />
              <Route path="/uslugi/lichnoe-konsultirovanie" element={<ConsultationPage setPageClass={setPageClass}/>} />
              <Route path="/uslugi/gruppovaya-terapiya" element={<GroupPage setPageClass={setPageClass}/>} />
              <Route path="/uslugi/obuchauschie-gruppi" element={<EducationGroupPage setPageClass={setPageClass}/>} />
              <Route path="/auth" element={<AuthorizationPage setPageClass={setPageClass} setToken={setToken} setUserData={setUserData}/>} />
              <Route path="/register" element={<WelcomePage setPageClass={setPageClass} status="noconfirm"/>} />
              <Route path="/confirm-activation/:uid/:token" element={<ConfirmRegisterPage setPageClass={setPageClass} status="noconfirm"/>} />
              <Route path="/password-reset-confirm/:uid/:token" element={<ConfirmPasswordPage setPageClass={setPageClass}/>} />
              <Route path="/lk/vosstanovlenie-parolya" element={<RecoveryPage setPageClass={setPageClass}/>} />
              <Route path="/lk/welcome" element={<WelcomePage setPageClass={setPageClass}/>} />
              <Route path="/lk/" element={<AccountPage setPageClass={setPageClass} setToken={setToken} token={token} />} >
                <Route path="profile" element={<ProfilePage setPageClass={setPageClass}/>} />
                <Route path="my-orders" element={<OrdersPage setPageClass={setPageClass}/>} />
                <Route path="my-orders/:type/:slug" element={<OrderPage setPageClass={setPageClass}/>} />
                <Route path="my-orders/appointments/:id" element={<BookingPage setPageClass={setPageClass}/>} />
                <Route path="support" element={<SupportPage setPageClass={setPageClass}/>} />
              </Route>
              <Route path='*' element={<NotFound />}/>
            </Routes>
          </Suspense>
          <Footer/>
          <GdprModal />
          {buttonUp && <button className="button_top" onClick={scrollTop}></button>}
        </div>
      </ViewportProvider>
    </BrowserRouter>
  );
}

export default App;
